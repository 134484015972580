.App {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: center;
}

.NavBar {
  visibility: visible;
}


.App-link {
  color: #61dafb;
}

@media (min-width: 650px) {
  .donatHeader {
    visibility: hidden;
  }

  .NavBar {
    visibility: hidden;
  }
}
